"use client";
// -------------- 组件 -------------------- //
import JDIProductCardV2 from "@/common-components-src/js/productCard_V2/index";
import SafeLink from "@/components/SafeLink";
import JumpLink from "@/components/JumpLink";
// -------------- 图标 -------------------- //
import ArrowRight from "@/assets/icons/ArrowRightBlack.svg";
// -------------- scss -------------------- //
import style from "./style.module.scss";
// -------------- Constants -------------------- //
const THREE_ITEMS = 3;
const SIX_ITEMS = 6;
const CONTAINER_SIZE_BIG = 1;
// ---------------埋点-------------------- //
import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25223";
interface NewArrivalsPropType {
  data: any;
}

export default function NewArrivals({ data }: NewArrivalsPropType) {
  const { externalData, dataBatch } = data?.moduleData;

  const columnNumber = data?.columnNumber;
  const jumpParams = dataBatch?.Redirect[0].Jump;
  const currency = data?.currencySymbol;

  // Check floor number to determine container length
  if (externalData.data?.list.length) {
    externalData.data.list.length = columnNumber === CONTAINER_SIZE_BIG ? SIX_ITEMS : THREE_ITEMS;
  }
  // -------------- Product Data -------------------- //
  const getProductData = (product: any, style: any, index: number) => {
    return {
      type: "1",
      skuId: product.skuId,
      skuName: product?.skuName,
      skuImg: product?.imageUrl,
      realPrice: product?.realPrice,
      originalPrice: product?.originalPrice,
      currency: currency,
      shouldShowCart: true,

      // ----- Styles ------ //
      realPriceJDIPriceParams: {
        integerStyle: {
          fontSize: "24px",
        },
      },
      containerStyle: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        ...style.containerStyle,
      },
      skuNameStyle: {
        fontSize: "16px",
      },
      skuImgStyle: {
        ...style.skuImgStyle,
      },
      trackingPointParams: genEventTrackingInfo(product, index),
    };
  };

  // -----------------埋点信息------------------------- //
  const genEventTrackingInfo = (product: any, index: number) => {
    const eventTrackingInfo = {} as { [key: string]: any };
    const json_param = { skuid: product.skuId.toString(), index: index };
    const expConfig = {
      useClick: false,
      stay_duration: 0,
      repeated: false,
      area_rate: 0.01,
    };
    eventTrackingInfo["productClickPoint"] = () => sendClickTrack.Home_Pro_NewArrivals(json_param);
    eventTrackingInfo["productParam"] = json_param;
    eventTrackingInfo["productExpConfig"] = {
      event_id: EXPOSURE_RECORD.Home_Pro_NewArrivals_Expo,
      config: expConfig,
      json_param: json_param,
    };
    return eventTrackingInfo;
  };

  // -------------- Container Styles -------------------- //
  const containerSizeSmall = {
    containerStyle: { maxWidth: "180px", maxHeight: "256px" },
    skuImgStyle: { width: "180px", height: "180px" },
  };
  const containerSizeLarge = {
    containerStyle: { maxWidth: "220px", maxHeight: "272px" },
    skuImgStyle: { width: "200px", height: "200px" },
  };
  const containerStyling = columnNumber === 1 ? containerSizeLarge : containerSizeSmall;
  // --------------------------------------------------- //
  return (
    <div className={style.container} style={{ width: columnNumber === 1 ? "100%" : "624px" }}>
      <div className={style.header}>
        <p className={style.title}>{dataBatch?.Redirect[0]?.title || "New arrivals"}</p>
        <div
          className={style.buttonContainer}
          onClick={() => {
            sendClickTrack.Home_Pro_NewArrivals_More();
          }}
          exptag={"exp|" + EXPOSURE_RECORD.Home_Pro_NewArrivals_More_Expo}
          data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
        >
          <JumpLink jumpParams={jumpParams}>
            <div className={style.button}>
              <p className={style.land_text}>{dataBatch?.Redirect[0].jumpButtonName || "Recent 30 days"}</p>
              <ArrowRight className={style.arrow_right} color="#1A1A1A" />
            </div>
          </JumpLink>
        </div>
      </div>
      <div className={style.cardContainer}>
        <div className={style.contentContainer} style={{ height: columnNumber === 1 ? "272px" : "256px" }}>
          {externalData &&
            externalData.data?.list.map((item: any, index: number) => {
              return <JDIProductCardV2 key={item.skuId} productData={getProductData(item, containerStyling, index)} />;
            })}
        </div>
      </div>
    </div>
  );
}
